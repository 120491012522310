<script lang="ts" setup>
defineProps<{
	fancyView?: boolean;
	small?: boolean;
}>();

const { BALLS, isCanPlay, counterBalls, collectedBalls } = useBingoData({
	immediate: false
});

const ballsArray = computed(() =>
	Array.from({ length: BALLS }, (_, index) => (index < collectedBalls.value ? getRandom({ min: 1, max: 90 }) : null))
);

const shuffle = (array: { light: string; dark: string }[]) => {
	for (let i = array.length - 1; i > 0; i--) {
		const j = Math.floor(Math.random() * (i + 1));
		[array[i], array[j]] = [array[j], array[i]];
	}
	return array;
};

const shuffledColors = computed(() => shuffle(BINGO_COLORS));
</script>

<template>
	<div :class="['progress-wrapper', { small: small, 'fancy-view': fancyView, 'active-state': fancyView && isCanPlay }]">
		<div class="progress">
			<template v-for="(ball, ballIndex) in ballsArray" :key="ballIndex">
				<MBingoBallDefault
					v-if="ball"
					:id="ballIndex"
					:key="`ball-default-${ballIndex}`"
					:light="shuffledColors[ballIndex].light"
					:dark="shuffledColors[ballIndex].dark"
				/>
				<NuxtIcon v-else :key="`hole-${ballIndex}`" class="hole" name="24/bingo-empty-ball" filled />
			</template>
		</div>
		<AText class="text-constanta" :size="12" :modifiers="['wide']" as="div">
			<i18n-t keypath="bingo.progress.info.text">
				<template #counter>
					<AText :class="[isCanPlay ? 'text-curico' : 'text-coro']" :size="12" :modifiers="['semibold']">{{
						counterBalls
					}}</AText>
				</template>
			</i18n-t>
		</AText>
	</div>
</template>

<style lang="scss" scoped>
.progress-wrapper {
	width: 100%;

	.progress {
		margin-bottom: 4px;
		border-radius: 100px;
		border: 1px solid var(--celaya);
		background: var(--neutral-950);
		padding: 2px;
		display: flex;
		justify-content: space-around;
		align-items: center;
		gap: 2px;

		& > * {
			position: relative;
			width: 22px;
			max-width: 22px;
			height: 22px;
			max-height: 22px;
			display: flex;
			justify-content: center;
			align-items: center;

			:deep(svg) {
				margin: 0;
				width: 100%;
				height: 100%;
			}
		}

		.hole {
			opacity: 0.5;
		}
	}

	&.small .progress {
		margin-bottom: 4px;
		padding: 2px;

		& > * {
			width: 12px;
			min-width: 12px;
			height: 12px;
		}
	}

	&.fancy-view {
		display: flex;
		gap: 8px;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: center;
		border-radius: 12px;
		border: 1px solid var(--neutral-800);
		padding: 16px;
		background: var(--carletonville);

		.progress {
			margin: 0;
		}
	}

	&.active-state {
		border: 1px solid var(--сirebon);
		box-shadow: 0px 3px 32px 0px var(--chhatarpur);
	}
}
</style>
